<template>
  <div class="my-4">
    <h1 class="text-center font-weight-bolder font-large-3">
      WELCOME TO <span style="color: #ff8b3d;">HEROAIX</span>
    </h1>
  </div>
</template>

<script>
export default {}
</script>
